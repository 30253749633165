import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  style: {
    "width": "100%",
    "display": "inline-flex",
    "align-items": "center"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_DeepSvgIcon = _resolveComponent("DeepSvgIcon");
  return _openBlock(), _createBlock($setup["Label"], {
    desc: $setup.workModeDesc,
    icon: $setup.icon,
    span: $props.span
  }, {
    value: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_DeepSvgIcon, {
        name: $setup.rateIndex,
        "class": "icon"
      }, null, 8 /* PROPS */, ["name"])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["desc", "icon", "span"]);
}