import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-eaadac24"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "selects"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_cascader = _resolveComponent("el-cascader");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_cascader, {
    disabled: $props.disabled,
    placeholder: "所有飞行器",
    "show-all-levels": false,
    props: $setup.vProps,
    modelValue: $setup.defaultValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $setup.defaultValue = $event;
    }),
    onChange: $setup.handleChange
  }, null, 8 /* PROPS */, ["disabled", "props", "modelValue"])]);
}