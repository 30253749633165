import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_menu = _resolveComponent("el-menu");
  return _openBlock(), _createBlock(_component_el_menu, {
    mode: "horizontal"
  }, {
    "default": _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.routes, function (route) {
        return _openBlock(), _createBlock($setup["SidebarItem"], {
          key: route.path,
          route: route,
          "base-path": route.basePath
        }, null, 8 /* PROPS */, ["route", "base-path"]);
      }), 128 /* KEYED_FRAGMENT */))];
    }),
    _: 1 /* STABLE */
  });
}