import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-b9269588"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "app-main"
};
export function render(_ctx, _cache) {
  var _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("section", _hoisted_1, [_createVNode(_component_router_view, null, {
    "default": _withCtx(function (_ref) {
      var Component = _ref.Component,
        route = _ref.route;
      return [_createVNode(_Transition, {
        name: "slide-fade-transform",
        mode: "out-in"
      }, {
        "default": _withCtx(function () {
          return [(_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
            key: route.path
          }))];
        }),
        _: 2 /* DYNAMIC */
      }, 1024 /* DYNAMIC_SLOTS */)];
    }),
    _: 1 /* STABLE */
  })]);
}