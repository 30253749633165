import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, normalizeStyle as _normalizeStyle, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-769edbb8"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "header"
};
var _hoisted_2 = {
  "class": "space",
  style: {
    "justify-content": "flex-start"
  }
};
var _hoisted_3 = {
  "class": "title"
};
var _hoisted_4 = {
  "class": "space"
};
var _hoisted_5 = {
  key: 0,
  "class": "bottom"
};
var _hoisted_6 = {
  "class": "space"
};
var _hoisted_7 = {
  "class": "space",
  style: {
    "flex": "1"
  }
};
var _hoisted_8 = {
  "class": "space"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createBlock($setup["DeepTeleport"], {
    disabled: !$setup.fullScreen,
    to: "body",
    style: _normalizeStyle({
      width: $props.aspectRelative === 'width' ? '100% !important' : undefined,
      height: $props.aspectRelative === 'height' ? '100% !important' : undefined
    })
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("div", _mergeProps({
        "class": "drone-player",
        style: $setup.fullScreen ? $setup.playerStyle : null
      }, _ctx.$attrs), [_createElementVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "header", {}, function () {
        return [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "top-left", {}, function () {
          var _a;
          return [_createTextVNode(_toDisplayString((_a = $setup.drone) === null || _a === void 0 ? void 0 : _a.gatewayName), 1 /* TEXT */)];
        }, true)]), _createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "top-middle", {}, undefined, true)]), _createElementVNode("div", _hoisted_4, [_renderSlot(_ctx.$slots, "top-right", {}, function () {
          return [_createVNode($setup["DeepSvgIcon"], {
            style: {
              "color": "white"
            },
            name: $setup.fullScreen ? 'fullscreen-exit' : 'fullscreen',
            onClick: $setup.handleDockScreen
          }, null, 8 /* PROPS */, ["name"])];
        }, true)])];
      }, true)]), _createVNode($setup["DeepAspectView"], {
        aspect: $setup.fullScreen ? 'fill' : $props.aspect,
        relative: $props.aspectRelative
      }, {
        "default": _withCtx(function () {
          return [_createVNode($setup["DeepTcplayer"], {
            drone: $setup.drone,
            type: "gateway"
          }, null, 8 /* PROPS */, ["drone"])];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["aspect", "relative"]), $props.showBottom && $setup.drone ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_renderSlot(_ctx.$slots, "bottom", {}, function () {
        return [_createElementVNode("div", _hoisted_6, [_renderSlot(_ctx.$slots, "bottom-left", {}, undefined, true)]), _createElementVNode("div", _hoisted_7, [_renderSlot(_ctx.$slots, "bottom-center", {}, undefined, true)]), _createElementVNode("div", _hoisted_8, [_renderSlot(_ctx.$slots, "bottom-right", {}, undefined, true)])];
      }, true)])) : _createCommentVNode("v-if", true)], 16 /* FULL_PROPS */)];
    }),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["disabled", "style"]);
}