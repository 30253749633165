import { renderSlot as _renderSlot, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3b86f335"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "desc"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_tooltip = _resolveComponent("el-tooltip");
  return _openBlock(), _createBlock($setup["DeepRowText"], {
    span: $props.span
  }, {
    title: _withCtx(function () {
      return [_createVNode(_component_el_tooltip, {
        placement: "top",
        content: $setup.props.desc
      }, {
        "default": _withCtx(function () {
          return [_renderSlot(_ctx.$slots, "icon", {}, function () {
            return [_createVNode($setup["DeepSvgIcon"], {
              name: $setup.props.icon,
              "class": "icon"
            }, null, 8 /* PROPS */, ["name"])];
          }, true)];
        }),
        _: 3 /* FORWARDED */
      }, 8 /* PROPS */, ["content"])];
    }),
    desc: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "value", {}, function () {
        return [_createTextVNode(_toDisplayString($props.value), 1 /* TEXT */)];
      }, true)])];
    }),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["span"]);
}