import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_option = _resolveComponent("el-option");
  var _component_el_select = _resolveComponent("el-select");
  return _openBlock(), _createBlock(_component_el_select, _mergeProps({
    modelValue: $setup.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $setup.value = $event;
    }),
    size: "small"
  }, _ctx.$attrs), {
    "default": _withCtx(function () {
      return [(_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.options, function (item) {
        return _createVNode(_component_el_option, {
          key: item.value,
          label: item.label,
          value: item.value
        }, null, 8 /* PROPS */, ["label", "value"]);
      }), 64 /* STABLE_FRAGMENT */))];
    }),
    _: 1 /* STABLE */
  }, 16 /* FULL_PROPS */, ["modelValue"]);
}