import { defineComponent, ref, computed } from 'vue';
import JlinkUtils from '@/common/global/JlinkUtils';
export default defineComponent({
  name: 'AppLinkTo',
  props: {
    to: {
      type: String,
      required: true
    }
  },
  setup: function setup(props) {
    var to = ref(props.to);
    var external = computed(function () {
      return JlinkUtils.regex.isExternal(to.value);
    });
    var type = computed(function () {
      if (external.value) {
        return 'a';
      }
      return 'router-link';
    });
    function linkProps(to) {
      if (external.value) {
        return {
          href: to,
          target: '_blank',
          rel: 'noopener'
        };
      }
      return {
        to: to
      };
    }
    return {
      type: type,
      linkProps: linkProps
    };
  }
});