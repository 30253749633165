import "core-js/modules/es.array.map.js";
import { __awaiter, __generator, __spreadArray } from "tslib";
import { defineComponent, nextTick, onMounted, ref, toRaw, watch } from 'vue';
import { getBusinessPackageList } from '@/api/api.business';
import { mittError } from '@/common/mitt/mitt';
import DataException from '@/common/errors/DataException';
import JlinkTask from '@/common/global/JlinkTask';
export default defineComponent({
  props: {
    labelDefault: {
      type: String,
      "default": ''
    },
    modelValue: {
      type: String,
      "default": undefined
    },
    clearAfter: {
      type: Boolean,
      "default": false
    }
  },
  emits: ['update:modelValue', 'change'],
  setup: function setup(props, ctx) {
    var cascaderRef = ref();
    var vProps = ref();
    onMounted(function () {
      vProps.value = {
        lazy: true,
        lazyLoad: function lazyLoad(node, resolve) {
          // constant { level, children, value, loading } = node
          JlinkTask.catchAwait(function () {
            return __awaiter(this, void 0, void 0, function () {
              var result;
              return __generator(this, function (_a) {
                switch (_a.label) {
                  case 0:
                    return [4 /*yield*/, getBusinessPackageList({
                      page: 0,
                      size: 1000
                    }).then(function (res) {
                      return res.data;
                    })];
                  case 1:
                    result = _a.sent();
                    resolve(result.map(function (i) {
                      return {
                        label: i.businessName,
                        value: i.businessId,
                        leaf: true
                      };
                    }));
                    return [2 /*return*/];
                }
              });
            });
          }, function () {
            resolve([]);
            return false;
          });
        }
      };
    });
    watch(function () {
      return props;
    }, function (v) {
      if (v.labelDefault) {
        nextTick(function () {
          cascaderRef.value.inputValue = v.labelDefault;
        });
      }
    }, {
      immediate: true
    });
    function handleChange(v) {
      var result = __spreadArray([], toRaw(v), true);
      try {
        ctx.emit('update:modelValue', result[0]);
        ctx.emit('change', result[0]);
      } catch (e) {
        mittError(new DataException('设备枚举值不合法'));
      }
      if (props.clearAfter) {
        cascaderRef.value.inputValue = '';
      }
    }
    return {
      vProps: vProps,
      handleChange: handleChange,
      cascaderRef: cascaderRef
    };
  }
});