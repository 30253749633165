import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-c2f30a1e"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "navbar"
};
var _hoisted_2 = {
  "class": "left"
};
var _hoisted_3 = {
  "class": "user-company-name"
};
var _hoisted_4 = {
  "class": "right"
};
var _hoisted_5 = {
  "class": "user"
};
var _hoisted_6 = {
  "class": "user-name"
};
var _hoisted_7 = {
  key: 0,
  "class": "point"
};
var _hoisted_8 = {
  key: 0,
  "class": "point"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_dropdown = _resolveComponent("el-dropdown");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode($setup["DeepCompanyIcon"], {
    "class": "logo"
  }), _createElementVNode("div", _hoisted_3, _toDisplayString($setup.user.companyName), 1 /* TEXT */)]), _createVNode($setup["Sidebar"], {
    "class": "middle"
  }), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_el_dropdown, {
    trigger: "click",
    onVisibleChange: $setup.handleImage
  }, {
    dropdown: _withCtx(function () {
      return [$setup.imageFlag ? (_openBlock(), _createBlock($setup["UserControl"], {
        key: 0,
        "class": "user-control"
      })) : _createCommentVNode("v-if", true)];
    }),
    "default": _withCtx(function () {
      return [_createVNode($setup["DeepUserIcon"], {
        src: $setup.user
      }, null, 8 /* PROPS */, ["src"])];
    }),
    _: 1 /* STABLE */
  }), _createElementVNode("div", _hoisted_6, _toDisplayString($setup.user.userName || '--'), 1 /* TEXT */)]), _createElementVNode("div", {
    "class": "button",
    onClick: $setup.openDownloadWorking
  }, [_createVNode($setup["DeepSvgIcon"], {
    name: "svg-download",
    style: {
      "color": "white"
    }
  }), $setup.downloadWorking ? (_openBlock(), _createElementBlock("div", _hoisted_7)) : _createCommentVNode("v-if", true)]), _createElementVNode("div", {
    "class": "button",
    onClick: $setup.openNotify
  }, [_createVNode($setup["DeepSvgIcon"], {
    name: "message",
    style: {
      "color": "white"
    }
  }), $setup.newMessageArrived ? (_openBlock(), _createElementBlock("div", _hoisted_8)) : _createCommentVNode("v-if", true)]), _createElementVNode("div", {
    "class": "button",
    onClick: $setup.logout
  }, [_createVNode($setup["DeepSvgIcon"], {
    name: "svg-power-off",
    style: {
      "color": "white"
    }
  })])])]);
}