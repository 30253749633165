import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  style: {
    "display": "inline-flex"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_cascader = _resolveComponent("el-cascader");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_cascader, {
    ref: "cascaderRef",
    placeholder: "选择设备类型",
    "show-all-levels": false,
    props: $setup.vProps,
    onChange: $setup.handleChange
  }, null, 8 /* PROPS */, ["props"])]);
}