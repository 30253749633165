import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_dropdown = _resolveComponent("el-dropdown");
  return _openBlock(), _createBlock(_component_el_dropdown, {
    trigger: "click",
    onVisibleChange: $setup.changeShow,
    "hide-on-click": false,
    placement: $props.placement
  }, {
    dropdown: _withCtx(function () {
      return [$setup.show ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        "class": _normalizeClass({
          'theme-dropdown': $props.classDefault
        })
      }, [_renderSlot(_ctx.$slots, "dropdown", {
        show: $setup.show
      }, undefined, true)], 2 /* CLASS */)) : _createCommentVNode("v-if", true)];
    }),
    "default": _withCtx(function () {
      return [_createElementVNode("div", null, [_renderSlot(_ctx.$slots, "default", {
        show: $setup.show
      }, undefined, true)])];
    }),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["placement"]);
}