import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-eccaf9a2"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "user"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(), _createBlock(_resolveDynamicComponent($setup.userComponent), {
    onPageChange: $setup.handlePageChange
  }, null, 32 /* NEED_HYDRATION */))]);
}