import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue";
var _hoisted_1 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_menu_item = _resolveComponent("el-menu-item");
  var _component_sidebar_item = _resolveComponent("sidebar-item", true);
  var _component_el_sub_menu = _resolveComponent("el-sub-menu");
  return !$setup.itemHidden ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [$setup.onlyOneChild && !$setup.onlyOneChild.children ? (_openBlock(), _createBlock(_component_el_menu_item, {
    key: 0,
    index: $setup.resolveRoute($setup.onlyOneChild)
  }, {
    title: _withCtx(function () {
      return [$setup.onlyOneChild.meta.title ? (_openBlock(), _createBlock($setup["AppLink"], {
        key: 0,
        to: $setup.resolveRoute($setup.onlyOneChild),
        style: {
          "height": "100%",
          "display": "flex",
          "align-items": "center",
          "width": "100%",
          "padding": "0 23px"
        }
      }, {
        "default": _withCtx(function () {
          return [_createElementVNode("span", null, _toDisplayString($setup.onlyOneChild.meta.title), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["to"])) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["index"])) : (_openBlock(), _createBlock(_component_el_sub_menu, {
    key: 1,
    index: $setup.item.path
  }, {
    title: _withCtx(function () {
      return [$props.route.meta.title ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString($props.route.meta.title), 1 /* TEXT */)) : _createCommentVNode("v-if", true)];
    }),
    "default": _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.item.children, function (child) {
        return _openBlock(), _createBlock(_component_sidebar_item, {
          key: child.path,
          route: child,
          "base-path": $setup.resolveRoute($setup.item)
        }, null, 8 /* PROPS */, ["route", "base-path"]);
      }), 128 /* KEYED_FRAGMENT */))];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["index"]))], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true);
}