import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-57829675"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "page-horizontal-layout"
};
var _hoisted_2 = {
  style: {
    "width": "100%",
    "height": "100%"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.rows, function (item, index) {
    return _openBlock(), _createElementBlock(_Fragment, null, [$props.hidden !== index ? (_openBlock(), _createElementBlock("div", {
      key: index,
      style: _normalizeStyle({
        height: '100%',
        width: item.span ? item.span * 10 + '%' : '0',
        flex: !item.span ? 1 : 'none',
        maxWidth: item.maxWidth,
        minWidth: item.minWidth
      })
    }, [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, 'layout' + index, {}, undefined, true)])], 4 /* STYLE */)) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */);
  }), 256 /* UNKEYED_FRAGMENT */))]);
}