import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-63161874"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  "class": "clarity-selector"
};
var _hoisted_2 = {
  "class": "clarity"
};
var _hoisted_3 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
  var _component_el_dropdown = _resolveComponent("el-dropdown");
  return !$setup.isTempDrone ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_dropdown, {
    ref: "dropdown1",
    trigger: "click",
    "hide-on-click": ""
  }, {
    dropdown: _withCtx(function () {
      return [_createVNode(_component_el_dropdown_menu, null, {
        "default": _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.clarityList, function (item, index) {
            return _openBlock(), _createElementBlock("div", {
              key: index,
              "class": _normalizeClass(["dropdown", $setup.currentClarity === item.value ? 'dropdown-selected' : '']),
              onClick: function onClick($event) {
                return $setup.handleClarity(item.value);
              }
            }, _toDisplayString(item.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_3);
          }), 128 /* KEYED_FRAGMENT */))];
        }),
        _: 1 /* STABLE */
      })];
    }),
    "default": _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "text", {
        data: $setup.currentClarityDesc
      }, function () {
        return [_createTextVNode(_toDisplayString($setup.currentClarityDesc), 1 /* TEXT */)];
      }, true)])];
    }),
    _: 3 /* FORWARDED */
  }, 512 /* NEED_PATCH */)])) : _createCommentVNode("v-if", true);
}