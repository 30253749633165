// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.page-horizontal-layout[data-v-57829675]{
  width: 100%;
  height: 100%;
  display: inline-flex;
}

`, "",{"version":3,"sources":["webpack://./src/app/layout/pagelayout/PageHorizontalLayout.vue"],"names":[],"mappings":";AA6BA;EACE,WAAW;EACX,YAAY;EACZ,oBAAoB;AACtB","sourcesContent":["<template>\n  <div class=\"page-horizontal-layout\">\n    <template  v-for=\"(item,index) in rows\">\n    <div :key=\"index\" :style=\"{height:'100%' ,width: item.span? item.span*10+'%':'0',flex: !item.span?1:'none',maxWidth:item.maxWidth,minWidth:item.minWidth}\" v-if=\"hidden!==index\">\n      <div style=\"width: 100%;height: 100%\">\n        <slot :name=\"'layout'+index\"></slot>\n      </div>\n    </div>\n    </template>\n  </div>\n\n</template>\n<!--横向布局-->\n<script lang=\"ts\"  setup>\n\nimport { computed, PropType } from 'vue'\n\nconst props = defineProps({\n  row: {\n    type: Object as PropType<{span:number, maxWidth?:string, minWidth?:string}[]>,\n  },\n  hidden: Number\n})\n\nconst rows = computed(() => props.row || [{ span: 2, maxWidth: '36rem', minWidth: '20rem' }, { span: 0 }])\n\n</script>\n\n<style scoped>\n.page-horizontal-layout{\n  width: 100%;\n  height: 100%;\n  display: inline-flex;\n}\n\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
