import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  style: {
    "display": "inline-flex",
    "width": "100%"
  }
};
var _hoisted_2 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_cascader = _resolveComponent("el-cascader");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_cascader, {
    placeholder: "选择飞行器类型",
    props: $setup.vProps,
    style: {
      "width": "100%"
    },
    options: $setup.options,
    "collapse-tags": "",
    modelValue: $setup.selectedValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $setup.selectedValue = $event;
    }),
    onChange: $setup.handleChange
  }, {
    "default": _withCtx(function (_a) {
      var node = _a.node,
        data = _a.data;
      return [_createElementVNode("span", null, _toDisplayString(data.label), 1 /* TEXT */), !node.isLeaf ? (_openBlock(), _createElementBlock("span", _hoisted_2, " (" + _toDisplayString(data.children.length) + ") ", 1 /* TEXT */)) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["props", "options", "modelValue"])]);
}